<template>
  <div class="print">
    <div class="print-header">
      <div class="print-header-title">
        {{translate('shop_offerResultsPrint')}}
      </div>
      <em class="icon icon-arrow-left-e"
          @click="goHome"></em>
    </div>
    <div class="print-body">
      <div class="print-body-navigation">
        <div :class="{active: activePrintType === 'offer'}"
             @click="setActivePrintType('offer')">
          {{translate('general_offer')}}
        </div>
        <div :class="{active: activePrintType === 'results'}"
             @click="setActivePrintType('results')">
          {{translate('shop_results')}}
        </div>
      </div>
      <div class="print-body-content">
        <div class="active-selection"
             @click="selectionsActive = !selectionsActive">
          <span>
            {{activePrint || translate(`shop_${activePrintType}Select`)}}
          </span>
          <em class="icon icon-arrows"></em>
        </div>
        <div class="selections-wrapper"
             v-if="selectionsActive">
          <div class="section"
               v-for="(item, index) in getProperPrint"
               :key="index"
               @click="setActivePrint(item)">
            {{ setPrintName(item) }}
          </div>
        </div>
        <div class="message-wrapper"
             v-if="message">
          <em class="icon icon-bell"></em>
          <div class="message">{{ message }}</div>
        </div>
        <button @click="preview"
                :disabled="!activePrint || disabled">
          {{translate('shop_printPreview')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import { mapActions, mapGetters } from 'vuex';
import { createHeaders, createQueryParams } from '@/api/utility';
import EventBus from '@/store/EventBus';
import { getData } from '@/api';
import { formatFns } from '@/utility';

export default {
  name: 'Print',
  data() {
    return {
      offerSelections: null,
      resultsSelections: null,
      selectionsActive: false,
      activePrint: null,
      activePrintTemplate: null,
      disabled: false,
      message: null,
    };
  },
  computed: {
    ...mapGetters([
      'translate',
      'activePrintType',
      'timezone',
      'language',
      'tenantId',
    ]),
    getProperPrint() {
      return this[`${this.activePrintType}Selections`];
    },
    getHeaders() {
      return {
        language: this.language,
        uuid: this.tenantId,
      };
    },
  },
  methods: {
    ...mapActions([
      'setPrintType',
      'setLoaderStatus',
      'busServiceSendMessage',
      'toggleShopSettings',
    ]),
    async setActivePrintType(type) {
      if (type === this.activePrintType) {
        return;
      }
      this.selectionsActive = false;
      this.activePrint = null;
      this.activePrintTemplate = null;
      this.setPrintType(type);
      await this.fetchPrints(type);
    },
    fetchPrints(name = '') {
      const urlOffer = `${process.env.VUE_APP_PRINT_OFFER}`;
      const headers = createHeaders(this.getHeaders);
      getData(urlOffer, headers).then((response) => {
        const filterData = (type) => filter(response.data, (item) => item.selection === type);
        if (!name || name === 'offer') {
          const data = filterData('offer');
          this.offerSelections = data;
        } else {
          const data = filterData('results');
          this.$set(this, 'resultsSelections', data);
        }
        // this.$set(this, 'offerSelections', [responses[0].data]);
        // this.$set(this, 'resultsSelections', responses[1].data);
        this.setLoaderStatus(false);
      }).catch(() => {
        this.busServiceSendMessage({
          action: 'Dialog.ShowModal',
          data: {
            action: '7S:Dialog.ShowModal',
            message: this.translate('shop_problemWithFetchPrints'),
            type: 'warning',
            delay: 3000,
          },
        });
        this.setLoaderStatus(false);
      });
    },
    setActivePrint(template) {
      this.selectionsActive = false;
      this.activePrint = template.name;
      this.activePrintTemplate = template;
    },
    async preview() {
      try {
        this.disabled = true;
        this.message = this.translate('shop_printPreviewIsGenerating');
        const { language } = this;
        const querryData = {
          timezone: this.timezone,
          offerId: this.activePrintTemplate.id,
          language,
          companyUuid: this.tenantId,
        };
        const url = `${process.env.VUE_APP_API_BASEURL}${process.env.VUE_APP_DOWNLOAD_OFFER}${createQueryParams(querryData)}`;
        const headers = createHeaders(this.getHeaders);
        await getData(url, headers);
        window.open(url, '_blank');
      } catch (e) {
        console.log(e);
      } finally {
        this.disabled = false;
        this.message = null;
      }
    },
    goHome() {
      EventBus.$emit('goToPage', 'home');
    },
    setPrintName(item) {
      return `${item.name}:  ${formatFns('DD.MM.YYYY. HH:mm', (item.start_date * 1000))}
      - ${formatFns('DD.MM.YYYY. HH:mm', (item.end_date * 1000))}`;
    },
  },
  mounted() {
    this.toggleShopSettings(false);
  },
  async beforeMount() {
    EventBus.$on('activateResults', () => {
      this.setActivePrintType('results');
    });
    this.setLoaderStatus(true);
    await this.fetchPrints();
  },
  beforeDestroy() {
    this.setActivePrintType('offer');
  },
};
</script>

<style lang="scss" scoped>
.print {
  display: flex;
  flex-direction: column;
  height: 100%;
  .print-header {
    align-items: center;
    background-color: #ebebeb;
    color: #465a65;
    display: flex;
    height: 70px;
    justify-content: space-between;
    padding: 0 15px;
    .print-header-title {
      font-size: 28px;
      font-weight: 600;
    }
    em {
      cursor: pointer;
      font-size: 32px;
    }
  }
  .print-body {
    padding: 15px 15px 0 15px;
    height: calc(100% - 70px);
    .print-body-navigation {
      align-items: center;
      border-bottom: 2px solid #465a65;
      display: flex;
      height: 36px;
      > div {
        color: rgba(69,90,100,0.5);
        cursor: pointer;
        width: 25%;
        &.active {
          color: #455a64;
          font-weight: 600;
        }
      }
    }
    .print-body-content {
      padding: 30px 0 30px 10px;
      height: calc(100% - 36px);
      position: relative;
      .active-selection {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .active-selection,
      .selections-wrapper {
        border-radius: 2px;
        border: 1px solid rgba(0,0,0,0.15);
        color: #495057;
        width: 570px;
      }
      .active-selection,
      .section {
        align-items: center;
        display: flex;
        height: 38px;
        padding: 0 10px;
      }
      .section {
        font-weight: 600;
        width: 100%;
        &:hover {
          background-color: #f5f5f5;
        }
      }
      .message-wrapper {
        background: #2f3744;
        border-radius: 5px;
        color: #fff;
        display: flex;
        position: absolute;
        top: 30px;
        right: 0;
        max-width: 250px;
        padding: 10px;
        .message {
          padding-left: 10px;
        }
      }
      button {
        background-color: #1F9BDE;
        border-radius: 2px;
        bottom: 15px;
        color: #fff;
        padding: 6px 12px;
        position: absolute;
        right: 0;
        &[disabled] {
          opacity: .6;
        }
      }
    }
  }
}
</style>
